.align-h-container {
    @include flex($direction: column, $wrap: wrap);
    align-items: flex-end;
    margin-bottom: 1.6rem;
    &>*:not(:last-child) {
        margin-right: 1.3em;
    }
    @include mq('phablet') {
        flex-flow: row nowrap;
    }
}
.align-v-container {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    margin-bottom: 1.6rem;
    &>*:not(:last-child) {
        margin-right: 1.3em;
    }
}

.hidden {
    display:none;
}
.show {
    display:block;
}

section.overlay {
    @include flex($direction: column);
    width: 100%;
    padding: 2% 3% 5%;

    @include mq('desktop-wide') {
        padding: 3.5% $global-padding 5%;
    }
    button.button-icon.close {
        position: absolute;
        top: 3rem;
        right: 3rem;
        width: initial;
        background-color: transparent;
        border-color: transparent;
        color: $grey-500;
        font-size: 1.4rem;
        &:hover {
            color: var(--primary, $primary);
        }
        svg-icon {
            width: 26px;
            margin-left: .5em;
            fill: currentColor;
            stroke: currentColor;
        }
    }
}

.no-data {
    width: 100%;
    padding: 3rem;
    background-color: var(--red, $red);
    text-align: center;
    color: $white;
    svg-icon {
        width: 64px;
        stroke: $white;
    }
    h2 {
        margin-bottom: 1rem;
    }
    h2,
    p {
        @include font(300, normal, ('Roboto-Light', 'Roboto'));
    }
}

.content-wrapper {
    @include mq('tablet') {
        flex-direction: row;
        margin-top: 120px;
        padding-top: 0;
    }
}
