.tooltip,
.tooltip-invalid {
    position: absolute;
    bottom: -3rem;
    right: 3rem;
    padding: 10px 25px;
    border-radius: 2px;
    background: var(--red, $red);
    color: $white;
    z-index: 10;
    &:after {
        @include pseudo;
        left: 43%;
        bottom: 100%;
        width: 0;
        height: 0;
        border-width: 7px;
        border-style: solid;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: var(--red, $red);
        border-left-color: transparent;
    }
}
